const getBaseURL = () => {
  switch (window.location.host) {
    case 'localhost:3000': {
      return 'http://localhost:5050'
    }
    case 'test.go.knackly.io': {
      return 'https://api.test.go.knackly.io'
    }
    case 'go.knackly.io': {
      return 'https://api.knackly.io'
    }
    case 'new.knackly.io': {
      return 'https://api.knackly.io'
    }
    case 'live.lightningdocs.com': {
      return 'https://api.knackly.io'
    }
    case 'builder.actionstep.com': {
      return 'https://api.knackly.io'
    }
    case 'plan.cotl.pet': {
      return 'https://api.knackly.io'
    }
    default: {
      return 'https://' + window.location.host
    }
  }
}

export default getBaseURL
