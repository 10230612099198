import React from 'react'
import { Input } from '../../index'
import { Modal } from 'antd'

const Relabel = ({ relabel, hideModal, saveModal, modalChange }) => {
  let _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveModal()
    }
  }
  return (
    <Modal
      title="Relabel"
      visible={relabel.visible}
      onOk={saveModal}
      onCancel={hideModal}
      okText="Save"
      cancelText="Cancel">
      <Input id={'relabelModal'} onKeyDown={_handleKeyDown} value={relabel.value} onChange={modalChange}/>
    </Modal>
  )
}

export default Relabel