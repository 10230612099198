import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

OptionList.propTypes = {
  lists: PropTypes.arrayOf(PropTypes.string),
  handelChange: PropTypes.func
}

function OptionList ({ lists, handelChange }) {
  return (
    <Fragment>
      <div className={'form-group'}>
        <div className={'w-100 border rounded px-2 py-3'}>
          <ol className={'m-0'}>
            {lists.map((list, index) => (<li key={list + index}>{list}</li>))}
          </ol>
        </div>
      </div>
      <div className={'form-group'}>
        <button className={'btn btn-link'} onClick={handelChange}>Add option</button>
      </div>
    </Fragment>
  )
}

export default OptionList