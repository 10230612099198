import React, { Fragment } from 'react'
import { Input } from '../../index'
import { Modal } from 'antd'
import { IsInvalidIdentifier } from '../../../utils'
import classNames from 'classnames'

const Rename = ({ rename, hideModal, saveModal, modalChange }) => {
  let _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveModal()
    }
  }
  return (
    <Modal
      title="Rename"
      visible={rename.visible}
      onOk={saveModal}
      onCancel={hideModal}
      okText="Save"
      cancelText="Cancel">
      <Fragment>
        <Input id={'renameModal'}
               className={classNames('form-control rounded-0', { 'is-invalid': rename.value && IsInvalidIdentifier(rename.value) })}
               onKeyDown={_handleKeyDown}
               value={rename.value}
               onChange={modalChange}/>
        <div
          className={classNames('invalid-feedback', { 'd-block': rename.value && IsInvalidIdentifier(rename.value) })}>
          {IsInvalidIdentifier(rename.value)}
        </div>
      </Fragment>
    </Modal>
  )
}

export default Rename